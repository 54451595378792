import React, { FC, useState, useEffect } from "react";
import {
  PhotoDetailContainerSC,
  PhotoDetailIconContainerSC,
  PhotoDetailIconSC,
  PhotoDetailItemSC,
  PhotoDetailTextSC,
  PhotoDetailTitleSC,
  FlexBoxSC,
  ImageSkelton
} from "./style";

import detail_edit from "../../images/detail_edit.svg";
import detail_delete from "../../images/detail_delete.svg";
import detail_download from "../../images/icon-download.svg";
import { PhotoDetailState } from "store/camera/mediafile/reducer";
import {
  PhotoDetailImg,
  PhotoDetailImgContainer
} from "components/Camera/pages/detail/style";
import { RadioButton } from "../Radiobutton";
import {
  CameraTag,
  CameraClass,
  CameraFacility,
  isMultiValue
} from "components/Camera/type";
import { Checkbox } from "../Checkbox";
import { MultiSelectBox } from "../SelectBox";
import { FixedButtonArea } from "../FixedButtonArea";
import { numArrayGen } from "utils/numArrayGen";
import { useDispatch, useSelector } from "react-redux";
import { updateMediaFileRequested } from "store/camera/mediafile/action";
import { RootState } from "store/store";
import { toggleImgLoadingState } from "store/camera/mediafiles/action";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
import ReactDatePicker from "react-datepicker";
import { format } from 'date-fns';

interface PhotoDetailProps {
  data: PhotoDetailState;
  tags: CameraTag[];
  classes: CameraClass[];
  facilities: CameraFacility[];
  onClickDeleteBtn: () => void;
}
export const PhotoDetail: FC<PhotoDetailProps> = ({
  data,
  onClickDeleteBtn,
  tags,
  classes,
  facilities
}) => {
  const {
    thumbnail_path,
    file_path,
    name,
    user,
    taken_at,
    share_ban,
    classes: currentClasses,
    tags: currentTags,
    facilities: currentFacilities
  } = data;
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const token = useSelector((state: RootState) => state.session.token);
  const { imgLoading } = useSelector((state: RootState) => state.mediafiles);
  const currentClassIds = currentClasses.map(i => i.id);
  const currentTagIds = currentTags.map(i => i.id);
  const currentFacilityOptions = currentFacilities.map(i => {
    return { label: i.name, value: i.id.toString() };
  });
  const currentFacilityIds = currentFacilities.map(i => i.id);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isShareBan, setIsShareBan] = useState(share_ban);
  const [selectedClassIds, setSelectedClassIds] =
    useState<number[]>(currentClassIds);
  const [selectedFacilityIds, setSelectedFacilityIds] =
    useState<number[]>(currentFacilityIds);
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(currentTagIds);
  const facilitiesOptions = facilities.map(facility => {
    return {
      value: facility.id.toString(),
      label: facility.name
    };
  });
  const [selectedTakenAt, setSelectedTakenAt] = useState<Date | null>(
    taken_at ? new Date(taken_at) : null
  );
  const handleToggleEditMode = () => {
    setIsEditMode(state => !state);
  };
  const handleChangeTags = (targetId: number) => {
    setSelectedTagIds(state => numArrayGen(state, targetId));
  };
  const handleChangeClasses = (targetId: number) => {
    setSelectedClassIds(state => numArrayGen(state, targetId));
  };
  const handleChangeFacilities = (value: isMultiValue) => {
    const newValue = value.map(v => Number(v.value));
    setSelectedFacilityIds(newValue);
  };
  const handleChangeShareBan = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === "true") {
      setIsShareBan(true);
    } else {
      setIsShareBan(false);
    }
  };
  const handleChangeTakenAt = (date: Date | null) => {
    setSelectedTakenAt(date);
  };
  const handleClickUpdate = () => {
    const updateData = {
      tag_ids: selectedTagIds,
      class_ids: selectedClassIds,
      facility_ids: selectedFacilityIds,
      taken_at: selectedTakenAt
        ? format(selectedTakenAt, 'yyyy-MM-dd HH:mm:ss')
        : null,
      share_ban: isShareBan
    };

    console.log('Update Data:', updateData);

    dispatch(toggleImgLoadingState(true));
    dispatch(updateMediaFileRequested(token, id, updateData));
    setIsEditMode(false);
  };
  useEffect(() => {
    setSelectedClassIds(currentClasses.map(i => i.id));
  }, [currentClasses]);
  useEffect(() => {
    setSelectedTagIds(currentTags.map(i => i.id));
  }, [currentTags]);
  useEffect(() => {
    setSelectedFacilityIds(currentFacilities.map(v => v.id));
  }, [currentFacilities]);

  const downloadImage = async () => {
    try {
      const response = await fetch(file_path);
      if (!response.ok) {
        throw Error("ダウンロード失敗");
      }
      const blob = await response.blob();
      saveAs(blob, name);
    } catch (e) {
      window.alert("ダウンロードに失敗しました");
      console.log("error", e);
    }
  };
  return (
    <>
      <PhotoDetailImgContainer>
        {imgLoading ? (
          <ImageSkelton />
        ) : (
          <PhotoDetailImg src={thumbnail_path} alt="test" />
        )}
      </PhotoDetailImgContainer>
      <PhotoDetailContainerSC>
        <PhotoDetailIconContainerSC>
          <PhotoDetailIconSC
            src={detail_download}
            alt="edit"
            onClick={downloadImage}
          />
          <PhotoDetailIconSC
            src={detail_edit}
            alt="edit"
            onClick={handleToggleEditMode}
          />
          <PhotoDetailIconSC
            src={detail_delete}
            alt="delete"
            onClick={onClickDeleteBtn}
          />
        </PhotoDetailIconContainerSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>ファイルID</PhotoDetailTitleSC>
          <PhotoDetailTextSC>{id}</PhotoDetailTextSC>
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>ファイル名</PhotoDetailTitleSC>
          <PhotoDetailTextSC>{name}</PhotoDetailTextSC>
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>共有施設</PhotoDetailTitleSC>
          {isEditMode ? (
            <div style={{ width: "80%" }}>
              <MultiSelectBox
                options={facilitiesOptions}
                onSelectValue={handleChangeFacilities}
                deafultValue={currentFacilityOptions}
              />
            </div>
          ) : (
            <PhotoDetailTextSC>
              {data.facilities.map((facility, i) => {
                if (i + 1 === data.facilities.length) {
                  return facility.name;
                } else {
                  return `${facility.name}, `;
                }
              })}
            </PhotoDetailTextSC>
          )}
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>設定タグ</PhotoDetailTitleSC>
          {isEditMode ? (
            <FlexBoxSC>
              {tags.map(tag => {
                return (
                  <Checkbox
                    id={`tag-${tag.id}`}
                    label={tag.name}
                    onChange={() => handleChangeTags(tag.id)}
                    checked={selectedTagIds.includes(tag.id)}
                  />
                );
              })}
            </FlexBoxSC>
          ) : (
            <PhotoDetailTextSC>
              {data.tags.map((tag, i) => {
                if (i + 1 === data.tags.length) {
                  return `#${tag.name}`;
                } else {
                  return `#${tag.name},`;
                }
              })}
            </PhotoDetailTextSC>
          )}
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>設定クラス</PhotoDetailTitleSC>
          {isEditMode ? (
            <FlexBoxSC>
              {classes
                .sort((a, b) => Number(a.name) - Number(b.name))
                .map(classroom => {
                  return (
                    <Checkbox
                      id={`class-${classroom.id}`}
                      label={classroom.name}
                      onChange={() => handleChangeClasses(classroom.id)}
                      checked={selectedClassIds.includes(classroom.id)}
                    />
                  );
                })}
            </FlexBoxSC>
          ) : (
            <PhotoDetailTextSC>
              {data.classes
                .sort((a, b) => Number(a.name) - Number(b.name))
                .map((classroom, i) => {
                  if (i + 1 === data.classes.length) {
                    return classroom.name;
                  } else {
                    return `${classroom.name},`;
                  }
                })}
              {data.classes.some(v => v.name === "6") ? "歳児以上" : "歳児"}
            </PhotoDetailTextSC>
          )}
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>公開設定</PhotoDetailTitleSC>
          {isEditMode ? (
            <FlexBoxSC>
              <RadioButton
                id="published"
                name="公開設定"
                label="公開"
                value="false"
                checked={!isShareBan}
                onChange={e => handleChangeShareBan(e)}
              />
              <RadioButton
                id="unpublished"
                name="公開設定"
                label="非公開"
                value="true"
                checked={isShareBan}
                onChange={e => handleChangeShareBan(e)}
              />
            </FlexBoxSC>
          ) : (
            <PhotoDetailTextSC>
              {share_ban ? "非公開" : "公開"}
            </PhotoDetailTextSC>
          )}
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>撮影日時</PhotoDetailTitleSC>
          {isEditMode ? (
            <div style={{ width: "300px" }}>
              <ReactDatePicker
                locale="ja"
                selected={selectedTakenAt}
                onChange={handleChangeTakenAt}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={5}
                dateFormat="yyyy/MM/dd HH:mm"
                isClearable
                placeholderText="日時を選択"
                customInput={
                  <PhotoDetailTextSC
                    as="input"
                    style={{
                      width: "100%",
                      padding: "8px 12px",  // パディングを追加
                      fontSize: "14px",     // フォントサイズを調整
                      height: "36px"        // 高さを指定
                    }}
                  />
                }
              />
            </div>
          ) : (
            <PhotoDetailTextSC>
              {taken_at ? new Date(taken_at).toLocaleString("ja-JP") : "不明"}
            </PhotoDetailTextSC>
          )}
        </PhotoDetailItemSC>
        <PhotoDetailItemSC>
          <PhotoDetailTitleSC>撮影者</PhotoDetailTitleSC>
          <PhotoDetailTextSC>{user.name}</PhotoDetailTextSC>
        </PhotoDetailItemSC>
      </PhotoDetailContainerSC>
      {isEditMode && <FixedButtonArea onUpdateFunc={handleClickUpdate} />}
    </>
  );
};
