// import { createStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import logger from "redux-logger";
import createSagaMiddleware from "redux-saga";

import accounts from "./global/accounts";
import account_new from "./global/account_new";
import account_edit from "./global/account_edit";

import article_new from "./faq/article_new";
import article_detail from "./faq/article_detail";
import article_edit from "./faq/article_edit";
import articles from "./faq/articles";
import articles_stocked from "./faq/articles_stocked";
import categories from "./faq/categories";
import categories_to_be_deleted from "./faq/categories_to_be_deleted";
import categories_to_be_created from "./faq/categories_to_be_created";
import categories_to_be_updated from "./faq/categories_to_be_updated";
import categories_to_be_searched from "./faq/categories_to_be_searched";
import tags from "./faq/tags";
import tags_to_be_deleted from "./faq/tags_to_be_deleted";
import tags_to_be_created from "./faq/tags_to_be_created";
import tags_to_be_updated from "./faq/tags_to_be_updated";
import tags_to_be_searched from "./faq/tags_to_be_searched";
import inquiries from "./faq/inquiries";
import inquiry_new from "./faq/inquiry_new";
import news from "./global/news";
import news_new from "./global/news_new";
import news_edit from "./global/news_edit";
import news_detail from "./global/news_detail";
import session from "./session";
import sidebar from "./common/sidebar";
import paginate from "./common/paginate";
import hamburger from "./common/hamburger";
import service from "./service";
import facilities from "./event/facilities";
import modal from "./common/modal";
import event_search from "./event/event_search";
import events from "./event/events";
import event_add from "./event/event_add";
import event_edit from "./event/event_edit";
import facility from "./event/facility";
import positions from "./common/positions";
import approval from "./event/approval";
import csv from "./faq/csv";
import events_top from "./event/events_top";
import event_csv from "./event/event_csv";
import monthly_events from "./event/monthly_events";
import monthly_event_add from "./event/monthly_event_add";
import monthly_event_edit from "./event/monthly_event_edit";
import monthly_event_delete from "./event/monthly_event_delete";
import histories from "./manual/histories";
import indexes from "./manual/indexes";
import manual_tags from "./manual/tags";
import manuals from "./manual/manuals";
import loading from "./loading";
import referer from "./global/referer";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import mediafile from "./camera/mediafile";
import mediafiles from "./camera/mediafiles";
import camera_tags from "./camera/tags";
import facilities_me from "./camera/facilities_me";
import classes from "./camera/classes";
import facility_users from "./camera/facility_users";
import inquiries_choices from "./faq/inquiries_choices";

// Devtoolsを使用するために宣言
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const history = createBrowserHistory();
// Reducerを一つにまとめる
const rootReducer = (history: any) => (state: any, action: any) => {
  const appReducer = combineReducers({
    accounts,
    account_new,
    account_edit,
    articles,
    article_new,
    article_detail,
    article_edit,
    articles_stocked,
    categories,
    tags,
    inquiries,
    inquiry_new,
    news,
    news_new,
    news_edit,
    news_detail,
    session,
    sidebar,
    categories_to_be_deleted,
    categories_to_be_created,
    categories_to_be_updated,
    categories_to_be_searched,
    tags_to_be_deleted,
    tags_to_be_created,
    tags_to_be_updated,
    tags_to_be_searched,
    paginate,
    hamburger,
    service,
    facilities,
    modal,
    event_search,
    events,
    event_add,
    event_edit,
    facility,
    positions,
    approval,
    csv,
    events_top,
    event_csv,
    monthly_events,
    monthly_event_add,
    monthly_event_edit,
    monthly_event_delete,
    manuals,
    histories,
    manual_tags,
    indexes,
    mediafile,
    mediafiles,
    camera_tags,
    classes,
    facilities_me,
    facility_users,
    loading,
    referer,
    router: connectRouter(history),
    inquiries_choices
  });
  if (action.type === "RESET_STATE") {
    // ログアウト時に全てのステートを初期状態にリセット
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    "accounts",
    "article_new",
    "article_detail",
    "article_edit",
    "articles",
    "articles_stocked",
    "categories",
    "tags",
    "histories",
    "inquiries",
    "inquiry_new",
    "manuals",
    "news",
    "news_edit",
    "news_detail",
    "session",
    "sidebar",
    "categories_to_be_searched",
    "tags_to_be_searched",
    "paginate",
    "account_new",
    "account_edit",
    "hamburger",
    "service",
    "modal",
    "facilities",
    "events",
    "event_edit",
    "event_add",
    "facility",
    "positions",
    "approval",
    "event_search",
    "event_csv",
    "monthly_event_add",
    "monthly_event_edit",
    "monthly_event_delete",
    "histories",
    "manual_tags",
    "manuals",
    "indexes",
    "mediafile",
    "mediafiles",
    "camera_tags",
    "classes",
    "facilities_me",
    "facility_users",
    "inquiries_choices"
  ]
};

const persistedReducer = persistReducer<any, any>(
  persistConfig,
  rootReducer(history)
);
const sagaMiddleware = createSagaMiddleware();

// 本番環境ではloggerを除外
const middlewares = [
  ...(process.env.NODE_ENV === "production" ? [] : [logger]),
  sagaMiddleware,
  routerMiddleware(history)
];

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

const persistor = persistStore(store);

export { store, persistor, sagaMiddleware };

export type RootState = ReturnType<typeof store.getState>;
