import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import { store, persistor, sagaMiddleware, history } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import rootSaga from "./saga";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { sentry_dns } from "./serverEnv";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

if (
  process.env.NODE_ENV === "production" &&
  process.env.REACT_APP_DISABLE_CONSOLE_LOG === "true"
) {
  const noop = () => {};

  // エラーログはSentryに送信できるように維持
  const originalConsoleError = console.error;

  // consoleのすべてのメソッドを無効化
  (Object.keys(console) as Array<keyof Console>).forEach(key => {
    if (key === "error") {
      console.error = (...args: unknown[]) => {
        // Sentryが設定されている場合のみエラーログを送信
        if (process.env.REACT_APP_SENTRY_DNS) {
          originalConsoleError.apply(console, args as [any?, ...any[]]);
        }
      };
    } else {
      (console[key] as any) = noop;
    }
  });
}

sagaMiddleware.run(rootSaga);

Sentry.init({
  dsn: sentry_dns,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <PersistGate loading={null} persistor={persistor}>
        <App history={history} />
      </PersistGate>
    </QueryClientProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
